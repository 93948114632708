import { ToggleStateService } from '@services/toggle-state/toggle-state.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { PdfPresentaionService } from '@services/pdf-presentation/pdf-presentation.service';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { VehicledataService } from '@services/vehicledata/vehicledata.service';
import { BrandCodeLogoEnum } from '@utils/enums/brand-code-logo.enum';
import { PdfActionEnum } from '@utils/enums/pdf-action.enum';
import { IVehicleDataGroup } from '@utils/interfaces/key-readout/vehicle-data-group.interface';
import { PdfContentService } from '@services/pdf-content/pdf-content.service';
import { TranslateService } from '@ngx-translate/core';
import { DefaultSettingsConstants } from '@utils/constants/default-settings.constants';
import { Subscription } from 'rxjs';

/**
 * This component contains the vehicle and key data information
 */
@Component({
  selector: 'app-key-data',
  templateUrl: './key-data.component.html',
  styleUrls: ['./key-data.component.scss'],
})
export class KeyDataComponent implements OnInit {
  // key data to be display on the key data pages
  public sessionStorageItem: any
  public groupServiceKey?: IVehicleDataGroup;
  public isToggleActive = false;
  public isFullyLoaded: boolean;

  //routing path do distinguish between key data and historical data
  public pathname: any


  isLoading: boolean = false;
  private subscription: Subscription;

  //input so that when called the data for groupServiceKey are also passed
  @Input() set call(calledData: IVehicleDataGroup) {
    this.groupServiceKey = calledData;
  }

  // path of the corresponding brand logo folder
  readonly brandFolderPath = 'assets/logos/brands/';

  // path of the corresponding translation path
  readonly translationPath = 'key-data.key-data-subtitle-info.';

  /**
   * Creates an instance of KeyDataComponent.
  *
   * @param translate communication with the internationalization (i18n) library
   * @param sessionStorage handles the session storage communication
   * @param pdfService controls the way a generated PDF is presented
   * @param pdfContentService generates the content of the PDF
   * @param missingDataService Checks whether the incoming data are empty
   * @param vehicledataService Service for the group service key information
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param toggleStateService stores and shares the state of the toggle component
   * @param localStorage handles the local storage communication
   * @param defaultSettings Recurring default values
   */
  constructor(
    private translate: TranslateService,
    public sessionStorage: SessionStorageService,
    private pdfService: PdfPresentaionService,
    private pdfContentService: PdfContentService,
    public missingDataService: MissingDataService,
    public vehicledataService: VehicledataService,
    public loadingIndicatorService: LoadingIndicatorService,
    private toggleStateService: ToggleStateService,
    private localStorage: LocalStorageService,
    private defaultSettings: DefaultSettingsConstants
  ) { }

  ngOnInit(): void {
    this.isToggleActive = JSON.parse(
      this.localStorage.getItem('toggleActive') || 'false'
    );
    this.toggleStateService.setToggleState(this.isToggleActive);
    // store the toggle state into the session storage.
    this.localStorage.setItem(
      'toggleActive',
      JSON.stringify(this.isToggleActive)
    );

    this.pathname = window.location.pathname;
    //Disable subscribtion to vehicle data changes when on histrical readout so that the data persist
    if (this.pathname !== '/historical-readout') {
      this.subscription = this.loadingIndicatorService.loading$.subscribe(isLoading => {
        this.isLoading = isLoading;
      });
      // Get key data information
      this.vehicledataService.replaySubject.subscribe({
        next: (groupServiceKey) => {
          this.groupServiceKey = groupServiceKey
        },
        complete: () => {
          this.subscription.unsubscribe();
          this.sessionStorageItem = sessionStorage.getItem('vin');
        }
      })
    }
  }


  public isKeyReadoutSuccessful(): boolean {
    if (this.sessionStorageItem !== '' && this.groupServiceKey?.vehicleData) {
      return true
    } else {
      return false;
    }
  }

  /**
   * Returns the status of the incoming timestamp. This can be "success", "warning", "danger" or "unknown".
   * This status is used for setting a style class. So an infotext takes different colors:
   * * success = green
   * * warning = yellow
   * * danger = red
   * * unknown = gray
   *
   * @param timestamp timestamp of data storage as unix timestamp in milliseconds (number) or as ZULU time code (string)
   * @returns status of this timestamp
   */
  public getDataStorageStatusClass(timestamp: number | string | null): string {
    // time span in milliseconds in which the status "success" is fulfilled, currently less than 2 hours
    const successTimeSpan: number = 7200000;
    // time span in milliseconds in which the status "warning" is fulfilled, currently less than 2 days
    // and the status "danger" is fulfilled, if its over 2 days
    const warningTimeSpan: number = 172800000;
    // if the timpestamp is null the status unknown is returned
    if (timestamp === null) {
      return 'unknown';
    } else {
      // generate unix time out of ZULU time
      const unixTime: number = new Date(timestamp).valueOf();
      if (successTimeSpan >= Date.now() - unixTime) {
        return 'success';
      } else if (warningTimeSpan >= Date.now() - unixTime) {
        return 'warning';
      } else {
        return 'danger';
      }
    }
  }

  /**
   * Returns the info text of the incoming timestamp. This can be "XX Minutes ago", "XX Hours ago", "XX Days ago" or "No information".
   *
   * @param timestamp timestamp of data storage as unix timestamp in milliseconds (number) or as ZULU time code (string)
   * @returns infotext for a timestamp
   */
  public getDataStorageInfoText(timestamp: number | string | null): string {
    // one hour in milliseconds
    const oneHourMillisecond: number = 3600000;
    // one day in milliseconds
    const oneDayMillisecond: number = 86400000;

    // if the timpestamp is null the info text "No information" is returned
    if (timestamp === null) {
      return (
        ' ' + this.translate.instant(this.translationPath + 'no-information')
      );
    } else {
      // generate unix time out of ZULU time
      const unixTime: number = new Date(timestamp).valueOf();
      // milliseconds into different units of time
      const miliseconds: number = Date.now() - unixTime;
      const total_seconds: number = Math.floor(miliseconds / 1000);
      const total_minutes: number = Math.floor(total_seconds / 60);
      const total_hours: number = Math.floor(total_minutes / 60);
      const days: number = Math.floor(total_hours / 24);

      if (oneHourMillisecond >= miliseconds) {
        return (
          ' | ' + this.translate.instant(this.translationPath + 'minutes-ago', { value: total_minutes })
        );
      } else if (oneDayMillisecond >= miliseconds) {
        return (
          ' | ' + this.translate.instant(this.translationPath + 'hours-ago', { value: total_hours })
        );
      } else {
        return (
          ' | ' + this.translate.instant(this.translationPath + 'days-ago', { value: days })
        );
      }
    }
  }

  /**
   * Routing to the printing view by calling the printing service with the given vehicle id.
   */
  onPrint(): void {
    if (this.pathname == '/historical-readout') {
      this.pdfContentService.dataFromHistoricalData(this.groupServiceKey);
    }

    const language: string = this.getApplicationLanguage();
    this.pdfService.createKeyDataDocument(
      PdfActionEnum.print,
      'data',
      language
    );
  }

  /**
   * calling the download functionality
   */
  onDownload(): void {
    const vin = this.groupServiceKey?.vehicleData?.data?.vin?.value
      ? this.groupServiceKey?.vehicleData?.data?.vin?.value.toString()
      : '';
    if (this.pathname == '/historical-readout') {
      this.pdfContentService.dataFromHistoricalData(this.groupServiceKey);
    }
    const language: string = this.getApplicationLanguage();

    this.pdfService.createKeyDataDocument(
      PdfActionEnum.download,
      'data',
      language,
      vin
    );
  }

  /**
   * Creates the path in which the logo belonging to the brand code is located.
   *
   * @param brandCode brand code of the present key information
   */
  displayBrandLogo(brandCode: any): string {
    const iconName: BrandCodeLogoEnum =
      BrandCodeLogoEnum[brandCode as keyof typeof BrandCodeLogoEnum];
    return this.brandFolderPath + iconName;
  }

  /**
   * method triggered by the toggle component.
   */
  toggleMissingData(): void {
    this.isToggleActive = !this.isToggleActive;
    this.toggleStateService.setToggleState(this.isToggleActive);
    this.localStorage.setItem(
      'toggleActive',
      JSON.stringify(this.isToggleActive)
    );
  }

  /**
   * Function that toggles the "Show Missing Data when Enter is pressed
   * Here only Enter key is used (and not Space) because the space key is covered by the groupuiChange event
   */
  onEnterPressedToggleMissingData(event: any): void {
    if (event.code == 'Enter') {
      this.toggleMissingData();
    }
  }

  /**
   * Return the language used in application
   *
   * @returns shortcut of the used language
   */
  getApplicationLanguage(): string {
    const language = localStorage.getItem('language');
    return language ? language : this.defaultSettings.defaultLanguage;
  }
}
