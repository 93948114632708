import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import htmlToPdfmake from 'html-to-pdfmake';
import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';
import { PdfContentService } from '../pdf-content/pdf-content.service';
import { PdfActionEnum } from '@utils/enums/pdf-action.enum';
import printJS from 'print-js';
import { fonts } from '@assets/fonts/pdfFonts.config';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from '@assets/fonts/vfs_fonts.js';
import { TranslateService } from '@ngx-translate/core';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
(<any>pdfMake).fonts = fonts;

/**
 * This service controls the way a generated PDF is presented
 */
@Injectable({
  providedIn: 'root',
})
export class PdfPresentaionService {
  // presentation process is currently active
  public isPresenting = false;

  /**
   * Creates an instance of PdfPresentaionService.
   *
   * @param router provides navigation among views
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param pdfContentService generates the content of the PDF
   * @param translate communication with the internationalization (i18n) library
   */
  constructor(
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
    private pdfContentService: PdfContentService,
    private translate: TranslateService
  ) {}

  /**
   * Method for navigating to the pdf view (called by clicking the download or print button in the key data view),
   * creating the pdf and navigating back to the key data view.
   *
   * @param action Action to be executed with the PDF
   * @param documentName name of the component that contains the document
   * @param documentTitle title of the PDF to download (on the key data pdf view the VIN)
   */
  createKeyDataDocument(
    action: PdfActionEnum,
    documentName: string,
    language: string,
    documentTitle?: string
  ): void {
    // A distinction to check which default font is to be used
    const fontName =
      language === 'CN' ||
      language === 'IL' ||
      language === 'KR' ||
      language === 'JP'
        ? language
        : 'NotoSans';

    this.isPresenting = true;

    // requests for translations which should be used in the PDF
    let useTranslation;

    this.translate
      .getTranslation(language)
      .toPromise()
      .then((res) => {
        useTranslation = res;

        // navigate to the pdf format
        const navigateToPrintView = [
          { outlets: { pdf: ['pdf', documentName] } },
        ];
        this.router.navigate(navigateToPrintView).then(async () => {
          this.loadingIndicatorService.show();
          // get the dom element that contains the pdf header, content and footer
          const HEADER = document.getElementById('pdf-header');
          const CONTENT = document.getElementById('pdf-content');
          const FOOTER = document.getElementById('pdf-footer');

          if (HEADER && CONTENT && FOOTER) {
            // generating the content
            this.pdfContentService.createPdfShell();
            this.pdfContentService.createKeyDataPDF(useTranslation);

            // content html is converted into a form readable by pdfMake
            const html = htmlToPdfmake(CONTENT.innerHTML, {
              window: window,
              // change the default styles
              defaultStyles: {
                font: fontName,
                color: '#000',
                fonsSize: 10,
                table: {
                  marginBottom: '',
                },
                th: {
                  fontSize: 12,
                  fillColor: '#FFFFFF',
                },
                td: {
                  fontSize: 8,
                  whiteSpace: 'pre',
                },
              }
            });

            // header html is converted into a form readable by pdfMake
            const header = htmlToPdfmake(HEADER.innerHTML);
            // footer html is converted into a form readable by pdfMake
            const footer = htmlToPdfmake(FOOTER.innerHTML);

            // general pdf properties
            var docDefinition = {
              header: header,
              footer: footer,
              content: [html],
              pageBreakBefore(
                currentNode: any,
                _followingNodesOnPage: any,
                _nodesOnNextPage: any,
                _previousNodesOnPage: any
              ) {
                return currentNode.pageNumbers.length > 1 && currentNode.id;
              },
              styles: {
                dtc: {
                  marginLeft: 5,
                  marginRight: 5,
                },
                'pdf-footer': {
                  alignment: 'center',
                },
                'align-right': {
                  whiteSpace: 'pre',
                  alignment: 'right',
                  width: 'auto',
                },
                fieldname: {
                  font: fontName,
                  bold: true,
                },
              },
              pageMargins: [40, 50, 40, 50],
              defaultStyle: {
                font: fontName,
              },
            };

            // create pdf
            const pdf = pdfMake.createPdf(docDefinition);

            // download or print pdf
            if (action === PdfActionEnum.download) {
              pdf.download(documentTitle);
            } else if (action === PdfActionEnum.print) {
              pdf.getBase64((base64: any) => {
                printJS({ printable: base64, type: 'pdf', base64: true });
              });
            }
          }

          // Navigate back to the key data view
          this.isPresenting = false;
          const navigateBack = [{ outlets: { pdf: null } }];
          this.router.navigate(navigateBack);
          this.loadingIndicatorService.hide();
        });
      });
  }
}
