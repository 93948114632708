import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import languages from '@assets/data/languages.json';
import { ILanguage } from '@utils/interfaces/language.interface';
import { IGdprRelevantElement } from '@utils/interfaces/gdpr/gdpr-relevant-element.interface';
import { MapService } from '@services/map/map.service';
import { EditGdprActionEnum } from '@utils/enums/edit-gdpr-action.enum';

/**
 * This component displays an overview for a topic of the already edited languages,
 * with the corresponding display mode and the default language for a GDPR related context.
 */
@Component({
  selector: 'app-language-table',
  templateUrl: './language-table.component.html',
  styleUrls: ['./language-table.component.scss']
})
export class LanguageTableComponent {
  // all existing gdpr relevant entries for a topic
  @Input() set content(content: IGdprRelevantElement[]) {
    this.defaultLanguageRow = content.find(({ default_language }) => default_language);
    this.tableContent = content.filter(({ default_language }) => !default_language);
    this.availableLanguages = languages.languages.filter(language => content.every(tableRow => tableRow.language !== language.countryCode));
    this.firstEntry = content.length === 0;
    this.newGdprContentForm.patchValue({
      default_language: this.firstEntry
    });
  };
  // Trigger when an element is to be updated or newly created
  @Output() changeContent = new EventEmitter<{ reason: EditGdprActionEnum, element: IGdprRelevantElement }>();
  // Emit the default language
  @Output() currentDefaultLanguage = new EventEmitter<IGdprRelevantElement>();

  // all languages that are still available, for creating new entries
  public availableLanguages: ILanguage[] = [];
  // Selected default language
  public defaultLanguageRow?: IGdprRelevantElement;
  // remaining table content besides the default language
  public tableContent: IGdprRelevantElement[] = [];
  // form to validate and create a new entry
  public newGdprContentForm = new FormGroup({
    default_language: new FormControl(false),
    language: new FormControl('', Validators.required),
    display_mode: new FormControl('', Validators.required)
  });
  // Contains all possible actions that can be performed
  public changeGdprActionEnum = EditGdprActionEnum;
  // marker that shows if the selection for a new entry is valid
  public invalidSelection = false;
  // flag if it is the first entry
  public firstEntry: boolean = true;

  /**
   * Creates an instance of the LanguageTableComponent
   *
   * @param mapService map functions
   */
  constructor(public mapService: MapService) {
    this.newGdprContentForm.patchValue({
      display_mode: null,
      language: null
    });
   }

  /**
   * Emitting the information to be changed (updated or created).
   *
   * @param reason the reason why the table content is changed (updated or created)
   * @param element content of the change
   */
  changeTableContent(reason: EditGdprActionEnum, element: IGdprRelevantElement): void {
    // emit the current default language (in case the default language should be changed)
    this.currentDefaultLanguage.emit(this.defaultLanguageRow);
    // emit the content that should be changed (updated or created).
    this.changeContent.emit({ reason, element });

    // Deselect the checkbox that is in the row where new table contents is added.
    const checkbox: any = document.getElementById('newDefaultLanguage');
    if (checkbox && (checkbox['checked'] !== null)) {
      checkbox['checked'] = false;
    }
  }

  /**
   * Toggle the value of the default language in the newGdprContentForm
   */
  toggleNewDefaultLanguage(): void {
    const isDefault = this.newGdprContentForm.get('default_language')?.value;
    this.newGdprContentForm.get('default_language')?.patchValue(!isDefault);
  }

  /**
   * is triggered when a new entry is to be created.
   * Before this happens, the new entry is evaluated for completeness here.
   * The form is cleaned up again.
   */
  onNewEntrySubmit(): void {
    this.invalidSelection = !this.newGdprContentForm.valid;

    const language = this.newGdprContentForm.get('language')?.value;
    const display_mode = this.newGdprContentForm.get('display_mode')?.value;
    let default_language = this.newGdprContentForm.get('default_language')?.value;

    if (this.newGdprContentForm.valid && language && display_mode && typeof default_language === 'boolean') {
      let reason = EditGdprActionEnum.add;
      // check if it is the first table entry
      if (this.firstEntry) {
        default_language = true;
      }

      const element: IGdprRelevantElement = { language, display_mode, default_language };
      this.changeTableContent(reason, element);
      this.newGdprContentForm.reset();

      this.newGdprContentForm.patchValue({
        default_language: false
      });
    }
  }
}
