import {Component, OnDestroy, OnInit} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { MapService } from '@services/map/map.service';
import { IGdprRelevantElement } from '@utils/interfaces/gdpr/gdpr-relevant-element.interface';
import { EditGdprActionEnum } from '@utils/enums/edit-gdpr-action.enum';
import { GdprService } from '@services/gdpr/gdpr.service';
import { HttpResponse } from '@angular/common/http';

/**
 * This component includes the admin section with the possibility
 * to edit the gdpr relevant content
 */
@Component({
  selector: 'app-edit-gdpr',
  templateUrl: './edit-gdpr.component.html',
  styleUrls: ['./edit-gdpr.component.scss'],
})
export class EditGdprComponent implements OnInit, OnDestroy {
  // currenty selected topic
  private selectedTopic = 'imprint';
  // currently selected default language
  public default_language?: IGdprRelevantElement;
  // currently selected gdpr relevant element for editing
  public selectedContent?: IGdprRelevantElement;
  // lag to be able to interact with the table or with the content editor. used to enable or disable the components via scss.
  public isContentEditorDisabled = true;
  // contains a list of gdpr relevant content for the currently selected topic
  public content$: Observable<IGdprRelevantElement[]> = of();
  // id's of tables that can be edited, used for the translation
  public editGdprTabIds: string[] = [
    'imprint',
    'terms-of-use',
    'privacy-policy',
  ];
  // reason why the modal window should be opened
  public modalReason!: EditGdprActionEnum;
  // action currently being carried out
  private action?: EditGdprActionEnum = undefined;
  // flag if the action should be confirmed
  private confirm: boolean = false;
  // existing text or file
  public editContent: string | File = '';
  // name of the uploaded/already existing file
  public fileName: string = '';
  // Subscription for the file upload progress
  private fileUploadProgress$: Subscription = new Subscription();

  /**
   * Creates an instance of the EditGdprComponent
   *
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param gdprService handles the gdpr information
   * @param mapService collection of reusable map functions
   */
  constructor(
    public loadingIndicatorService: LoadingIndicatorService,
    public gdprService: GdprService,
    private mapService: MapService
  ) {}

  ngOnInit(): void {
    // update the current selected topic on initial load
    this.updateTopic();
  }

  /**
   * Change the tab of the gdpr relavant tabs
   *
   * @param tab id of the selected tab in kebab case. By default/initial load the imprint is selected.
   */
  updateTopic(tab: string = 'imprint'): void {
    this.selectedTopic = this.mapService.mapToCamel(tab);
    this.content$ = this.gdprService.getGdprEntriesForTopic(this.selectedTopic);
  }

  /**
   * Open or close the modal window.
   *
   * @param show the modal window should be opened (true) or closed (false)
   */
  toggleModalWindow(show: boolean): void {
    const modalWindow: any = document.getElementById('editGdprModal');
    if (modalWindow && modalWindow['displayed'] !== null) {
      modalWindow['displayed'] = show;
    }
  }

  /**
   * Set the current default language triggered by the LanguageTableComponent
   *
   * @params language and display mode of the default language
   */
  setCurrentDefaultLanguage(element: IGdprRelevantElement): void {
    this.default_language = element;
  }

  /**
   * Here the various actions and the associated operations are carried out or the actions are discarded.
   *
   * @param performAction Should the action be performed (true) or should the changes be discarded (false)
   * @param content reason, display mode, language and info about defaut language and the editable content
   */
  performModalAction(
    performAction: boolean,
    content?: {
      reason: EditGdprActionEnum;
      element: IGdprRelevantElement;
      content?: any;
    }
  ): void{
    // Should the reason and content if available, set the modal reasen and the selected content
    if (content) {
      this.modalReason = content!.reason;
      this.selectedContent = content!.element;
      this.editContent = content.content;
    }
    // If the executed action is the same as the reason, the reason should executed.
    this.confirm = this.action !== this.modalReason;
    // Should the action be executed, or was the "back" button clicked and the aktion should be ignorred
    if (performAction) {
      switch (this.modalReason) {
        // is executed when changes are to be discarded
        case EditGdprActionEnum.discardChanges:
          this.toggleModalWindow(this.confirm);
          this.isContentEditorDisabled = !this.confirm;
          if (!this.confirm) {
            this.selectedContent = undefined;
            this.editContent = '';
            this.fileName = '';
          }
          this.action = this.modalReason;
          break;
        // is executed when the default should be deleted
        case EditGdprActionEnum.deleteDefault:
          this.selectedContent = undefined;
          this.toggleModalWindow(true);
          break;
        // is executed when a new entry should be added or updated
        case EditGdprActionEnum.add:
          this.isContentEditorDisabled = false;
          this.action = this.modalReason;
          break;
        case EditGdprActionEnum.update:
          this.gdprService
            .getGdprData(this.selectedTopic, this.selectedContent!.language)
            .subscribe((res: HttpResponse<ArrayBuffer>) => {
              // check if the content type is a text
              const text = res.headers.get('Content-Type') === 'text/html';
              // for text
              if (res.body && text) {
                this.editContent = String.fromCharCode.apply(
                  null,
                  Array.from<number>(new Uint8Array(res.body))
                );
                const modalWindowContent =
                  document.getElementById('displayText');
                if (modalWindowContent) {
                  // modalWindowContent.innerHTML = this.editContent;
                }
                // for a file
              } else if (res.url) {
                const myRegexp = RegExp(
                  /(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/,
                  'g'
                );
                var match = myRegexp.exec(res.url);
                this.fileName = match![0];
              }
            });
          this.isContentEditorDisabled = false;
          this.action = this.modalReason;
          break;
        // is executed when an entry should be deleted
        case EditGdprActionEnum.delete:
          this.toggleModalWindow(this.confirm);
          this.action = this.modalReason;
          if (!this.confirm) {
            this.action = undefined;
            this.gdprService
              .deleteGdprContent(this.selectedTopic, this.selectedContent!)
              .then(() => {
                this.selectedContent = undefined;
                this.updateTopic(this.selectedTopic);
                this.action = undefined;
              });
          }
          break;
        // is executed when the default should be changed
        case EditGdprActionEnum.changeDefault:
          this.action = this.modalReason;
          this.toggleModalWindow(this.confirm);
          if (!this.confirm) {
            this.gdprService
              .changeGdprDefaultLanguage(
                this.selectedTopic,
                this.selectedContent!
              )
              .then(() => {
                this.selectedContent = undefined;
                this.updateTopic(this.selectedTopic);
                this.action = undefined;
              });
          }
          break;
        // is executed when an entry should be saved
        case EditGdprActionEnum.save:
          this.toggleModalWindow(this.confirm);
          this.isContentEditorDisabled = !this.confirm;
          if (!this.confirm && this.editContent) {
            // upload the gdpr data
            this.gdprService
              .uploadGdprData(
                this.selectedTopic,
                this.selectedContent!,
                this.editContent
              )
              .then(() => {
                if (this.editContent instanceof File) {
                  // wait until the pdf is uploaded to reset the content and refresh the page
                  this.fileUploadProgress$ =
                    this.gdprService.progress$.subscribe((data) => {
                      if (data === 100) {
                        this.resetUploadVariables();
                        this.updateTopic(this.selectedTopic);
                      }
                    });
                } else {
                  this.resetUploadVariables();
                  this.updateTopic(this.selectedTopic);
                }
              });
          }
          this.action = this.modalReason;
          break;
        default: {
          break;
        }
      }
      // If the change of the default language is not confirmed, the checkbox must be deselected here again.
    } else if (
      !performAction &&
      this.modalReason === EditGdprActionEnum.changeDefault &&
      this.default_language !== undefined
    ) {
      const checkbox: any = document.getElementById(
        'checkbox-' + this.selectedContent!.language
      );
      if (checkbox && checkbox['checked'] !== null) {
        checkbox['checked'] = false;
      }
      this.selectedContent = undefined;
      this.toggleModalWindow(false);
      this.action = undefined;
      // when the "back" button is clicked and the action should be ignorred
    } else {
      this.toggleModalWindow(false);
      this.action = undefined;
    }
  }

  /**
   * Reset the upload related variables
   */
  private resetUploadVariables(): void {
    this.selectedContent = undefined;
    this.editContent = '';
    this.fileName = '';
  }

  ngOnDestroy(): void {
    this.fileUploadProgress$.unsubscribe();
  }

  showSelection($event: Event) {
    console.log($event);

  }
}
