import { HttpClient, HttpErrorResponse, HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { WebMessageVehicleData } from '@utils/interfaces/web-messages/web-message-data.enum';
import { WebMessageAction } from '@utils/interfaces/web-messages/web-message-action.enum';
import { IVehicleDataGroup } from "@utils/interfaces/key-readout/vehicle-data-group.interface";
import { IKeyDataResponse } from "@utils/interfaces/key-readout/key-readout-response.interface";

/**
 * This service handles the key data information
 */
@Injectable({
  providedIn: 'root',
})
export class VehicledataService {
  // contains the last edited key data element
  public replaySubject = new ReplaySubject<IVehicleDataGroup>(1);

  /**
   * Creates an instance of VehicledataService.
   *
   * @param http methods to perform HTTP requests
   * @param router provite navigation among the views
   * @param sessionStorageService handles the session storage communication
   * @param loadingIndicatorService contains the handling of the loading indicator
   */
  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    // register web message handler for keydata requests
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) => {
      this.receiveKeyReadoutStatusFromTrayApp(event);
    });
  }

  /**
   * Listen for web messages coming from the trayapp to send the language to webapp
   *
   * @param event message event with the Webmessage action and data
   */
  public receiveKeyReadoutStatusFromTrayApp(event: MessageEvent): void {
    const { action, data } = JSON.parse(event.data);
    if (action == WebMessageAction.SendKeyDataStart) {
      this.loadingIndicatorService.show();
    } else if (
      action == WebMessageAction.SendKeyDataEnd 
    ) {
      // If the key data view is not yet open, navigate to that view
      if (this.router.url !== '/data') {
        this.router.navigate(['/data']);
      }

      this.getVehicleData().then(() => {
        this.loadingIndicatorService.hide();
      });
    }
    // do nothing if non of the previous conditions are met
    return;
  }

  /**
   * Get the key data from the getvehicledata endpoint and pass it on to the vehicledata service subject
   */
  getVehicleData(): Promise<void> {
    // request header
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let options = {
      headers: headers,
    };
    return this.http
      .post<IKeyDataResponse>('/da/keydata/details/latest', null, options)
      .toPromise()
      .then(
        // Should the request be successful
        (res) => {
          if (Object.keys(res.data).length !== 0) {
            // Set vin for the key data page to know data is available
            const incommingVin = res.data.vehicleData.data.vin.value;
            this.sessionStorageService.setItem('vin', incommingVin.toString());
            // Add the info that key data is available to the session storage
            this.sessionStorageService.setItem('keyDataAvailable', 'true');
            this.replaySubject.next({
              version: res.data.version, // Assuming 'version' is part of the response
              vehicleData: res.data.vehicleData,
              serviceKeyData: res.data.serviceKeyData,
              emissionData: res.data.emissionData
            });
          } else {
            this.replaySubject.next({
              version: null,
              vehicleData: null,
              serviceKeyData: null,
              emissionData: null
            });
            this.sessionStorageService.setItem('keyDataAvailable', 'true');
            this.sessionStorageService.removeAllItems();
          }
        },
        // In case of error
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            // Clearing the previous data
            this.replaySubject.next({
              version: null,
              vehicleData: null,
              serviceKeyData: null,
              emissionData: null
            });
            this.sessionStorageService.removeAllItems();
          }
        }
      )

  }

  /**
   * complete the subject on destroy
   */
  ngOnDestroy() {
    this.replaySubject.complete();
  }
}
