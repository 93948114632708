import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { IGdprRelevantElement } from '@utils/interfaces/gdpr/gdpr-relevant-element.interface';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { MapService } from '@services/map/map.service';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { ContentEditorModeEnum } from '@utils/enums/content-editor-mode.enum';
import { DefaultSettingsConstants } from '@utils/constants/default-settings.constants';

/**
 * This service handles the gdpr information
 */
@Injectable({
  providedIn: 'root',
})
export class GdprService implements OnDestroy {
  // used timeout for the DB update after finished file upload
  readonly timeoutForDbUpdate: number = 2000;
  // used time steps for the timeout of the DB update after finished file upload
  readonly timeoutSteps: number = 500;
  // allready reached timeout of the DB update after finished file upload
  private reachedTime: number = 0;
  // allready loaded pdf
  private loadedPdf: number = 0;
  // contains the last edited progress status
  private _progress = new BehaviorSubject<number | null>(null);
  // share the last edited progress element
  public readonly progress$ = this._progress.asObservable();

  /**
   * Creates an instance of the GdprService.
   *
   * @param http methods to perform HTTP requests
   * @param localStorage handles the local storage communication
   * @param mapService collection of reusable map functions
   * @param sessionStorage handles the session storage communication
   * @param defaultSettings Recurring default values
   */
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private mapService: MapService,
    private sessionStorage: SessionStorageService,
    private defaultSettings: DefaultSettingsConstants
  ) {}

  /**
   * get the gdpr content for a topic and language from the
   *
   * @param gdprItem selected gdpr item from the footer
   * @returns gdpr content for the selected topic and used language
   */
  getGdprData(
    gdprItem: string,
    language?: string
  ): Observable<HttpResponse<ArrayBuffer>> {
    // get the current selected language which is stored in the local storage
    const localStorageLanguage = this.localStorage.getItem(
      this.defaultSettings.localStorageLanguageVariable
    );

    // query parameter
    const selectedLanguage = language
      ? language
      : localStorageLanguage
      ? localStorageLanguage
      : this.defaultSettings.defaultLanguage;

    const params = new HttpParams()
      .set(
        'topic',
        this.mapService.mapToSnakeCase(this.mapService.mapToCamel(gdprItem))
      )
      .set('language', selectedLanguage);

    const url = '/da/gdpr/entry/get';
    const url_manuals = '/da/get_user_manuals';
    const url_third_party_licenses = '/da/get-third-party-licences';

    let options = {
      params: params,
    };

    // If the topic parameter is 'help' call the 'get_user_manuals' endpoint.
    if (gdprItem === 'help') {
      return this.http.get(url_manuals, {
        ...options,
        responseType: 'arraybuffer',
        observe: 'response',
      });
    }

    if (gdprItem === 'third-party-licenses') {
      const param = new HttpParams().set(
        'version',
        this.sessionStorage.getItem('version')
      );

      let option = {
        params: param,
      };

      return this.http.get(url_third_party_licenses, {
        ...option,
        responseType: 'arraybuffer',
        observe: 'response',
      });
    }

    return this.http.post(url, null, {
      ...options,
      responseType: 'arraybuffer',
      observe: 'response',
    });
    // ----------------------------------------------------------------------//
  }

  /**
   * Call the '/da/gdpr/entry/get' endpoint to get the GDPR data content for a specific topic and language.
   *
   * @param topic imprint, terms of use, data protection declaration, help or contact
   * @param element element for which the content is requested
   * @returns html as string
   */
  getGdprContent(
    topic: string,
    element: IGdprRelevantElement
  ): Promise<string> {
    // Part 1 - Mock data (local) -------------------------------------------//
    // If the application should be started with local mock data,
    // part 1 must be commented in and part 2 must be commented out.

    // return of('<p>Test String in p Tag</p>').toPromise();
    // ----------------------------------------------------------------------//

    // Part 2 - Real data (AWS) ---------------------------------------------//
    // If the application should be started with real data,
    // part 2 must be commented in and part 1 must be commented out.

    // request header
    let headers = new HttpHeaders({
      'Content-Type': 'text/html',
    });

    // query parameter
    const params = new HttpParams()
      .set('topic', this.mapService.mapToSnakeCase(topic))
      .set('language', element.language);

    const url = '/da/gdpr/entry/get';

    let options = {
      headers: headers,
      params: params,
    };

    return this.http
      .post(url, null, { ...options, responseType: 'text' })
      .toPromise();
  }

  /**
   * Call the '/da/gdpr/entries' endpoint to get an overview of the GDPR data for a specific topic.
   *
   * @param topic imprint, terms of use, data protection declaration, help or contact
   * @returns a array of gdpr relevant elements for a specific topic
   */
  getGdprEntriesForTopic(topic: string): Observable<IGdprRelevantElement[]> {
    // Part 1 - Mock data (local) -------------------------------------------//
    // If the application should be started with local mock data,
    // part 1 must be commented in and part 2 must be commented out.
    //
    // const path = window.location.pathname + '/../assets/mock-data/gdpr/gdpr-entries-' + topic + '.json';
    // return this.http.get<any>(path);
    // ----------------------------------------------------------------------//

    // Part 2 - Real data (AWS) ---------------------------------------------//
    // If the application should be started with real data,
    // part 2 must be commented in and part 1 must be commented out.

    // request header
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // query parameter
    const params = new HttpParams()
      .set('topic', this.mapService.mapToSnakeCase(topic))
      .set(
        'market',
        this.sessionStorage.getItem(
          this.defaultSettings.localStorageMarketVariable
        )
      );

    const url = '/da/gdpr/entries';

    let options = {
      headers: headers,
      params: params,
    };

    return this.http.post<any>(url, null, options);
    // ----------------------------------------------------------------------//
  }

  /**
   * Call the '/da/gdpr/entry/upload' endpoint to upload new GDPR relevant content for a specific topic and language.
   *
   * @param newElement element for which the content is updated
   * @returns information if request was successful
   */
  uploadGdprData(
    topic: string,
    newElement: IGdprRelevantElement,
    content: File | string
  ): Promise<any> {
    const snakeCaseTopic = this.mapService.mapToSnakeCase(topic);
    const url = '/da/gdpr/entry/save';
    let headers: any = null;
    let options: any = null;

    // query parameter
    let params = new HttpParams()
      .set('topic', snakeCaseTopic)
      .set('default_language', newElement.default_language)
      .set('language', newElement.language);

    // for text
    if (newElement.display_mode === ContentEditorModeEnum.text) {
      // request header
      headers = new HttpHeaders({
        'Content-Type': 'text/html',
      });
      options = {
        headers: headers,
        params: params,
      };
      // call the endpoint to upload a file
      return this.http
        .post<any>(url, content, { ...options, responseType: 'text' })
        .toPromise();
      // for a file
    } else if (content instanceof File) {
      headers = new HttpHeaders({
        'Content-Type': 'application/pdf',
      });
      options = {
        headers: headers,
        params: params,
      };

      const formData = new FormData().append('file', content);
      // call the endpoint to get the presigned url and aws credentials
      return this.http
        .post<any>(url, formData, { ...options })
        .toPromise()
        .then((res: any) => {
          const fileForm = new FormData();
          fileForm.append('file', content);
          return this.uploadfileAWSS3(res, content);
        });
    }
    return of().toPromise();
  }

  /**
   * Upload the file to aws, using a presigned URL
   *
   * @param res Required credentials and parameters for the file upload
   * @param file file to be uploaded
   * @returns information if upload was successful
   */
  private uploadfileAWSS3(res: any, file: File): Subscription {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const formData: FormData = new FormData();
    const fields = res.fields;
    formData.append('key', fields.key);
    formData.append(
      'x-amz-meta-default-language',
      fields['x-amz-meta-default-language']
    );
    formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
    formData.append('x-amz-credential', fields['x-amz-credential']);
    formData.append('x-amz-date', fields['x-amz-date']);
    formData.append('x-amz-security-token', fields['x-amz-security-token']);
    formData.append('policy', fields['policy']);
    formData.append('x-amz-signature', fields['x-amz-signature']);
    formData.append('file', file, file.name);

    // call the presigned url to upload a file
    const upload$ = this.http.post(res.url, formData, {
      headers: headers,
      observe: 'events',
      reportProgress: true,
    });

    // Upload the file to aws
    return upload$.subscribe((event) => {
      // calculate the progress of the file upload
      if (event.type == HttpEventType.UploadProgress && event.total) {
        const total = event.total;
        this.loadedPdf = event.loaded;
        this.setLoadingProcess(this.loadedPdf, total + this.timeoutForDbUpdate);
        // if the upload finished, add some time for the DB update
        if (this.loadedPdf / total === 1) {
          this.reachedTime = this.loadedPdf;
          let timerId = setInterval(
            () => this.handleTimeoutForDbUpdate(),
            this.timeoutSteps
          );
          setTimeout(() => {
            this.resetUploadProgress(timerId);
          }, this.timeoutForDbUpdate);
        }
      }
    });
  }

  /**
   * Calculate the progress of the file upload in percent
   *
   * @param dataReached already reached data
   * @param dataToBeReached data which is to be reached
   */
  private setLoadingProcess(
    dataReached: number,
    dataToBeReached: number
  ): void {
    this._progress.next(Math.round(100 * (dataReached / dataToBeReached)));
  }

  /**
   * Handle the timeout for the DB upload
   */
  private handleTimeoutForDbUpdate(): void {
    this.reachedTime += this.timeoutSteps;
    this.setLoadingProcess(
      this.reachedTime,
      this.loadedPdf + this.timeoutForDbUpdate
    );
  }

  /**
   * Reset the interval, upload and progress state after a success upload
   *
   * @param timerId interval which should be cleared
   */
  private resetUploadProgress(timerId: any): void {
    clearInterval(timerId);
    this._progress.next(null);
  }

  /**
   * Call the gdpr endpoint to change the default language for a specific topic.
   *
   * @param topic imprint, terms of use, data protection declaration, help or contact
   * @param newDefaultElement element for which the content is updated
   * @returns information if request was successful
   */
  changeGdprDefaultLanguage(
    topic: string,
    newDefaultElement: IGdprRelevantElement
  ): Promise<any> {
    // query parameter
    const params = new HttpParams()
      .set('topic', this.mapService.mapToSnakeCase(topic))
      .set(
        'market',
        this.sessionStorage.getItem(
          this.defaultSettings.localStorageMarketVariable
        )
      )
      .set('language', newDefaultElement.language);

    const url = '/da/gdpr/entry/default';
    let options: any = null;

    options = {
      params: params,
    };

    return this.http
      .post<any>(url, null, { ...options, responseType: 'text' })
      .toPromise();
  }

  /**
   * Call the gdpr endpoint to change the default language for a specific topic.
   *
   * @param topic imprint, terms of use, data protection declaration, help or contact
   * @param newDefaultElement element for which the content is updated
   * @returns information if request was successful
   */
  deleteGdprContent(
    topic: string,
    newDefaultElement: IGdprRelevantElement
  ): Promise<any> {
    // query parameter
    const params = new HttpParams()
      .set('topic', this.mapService.mapToSnakeCase(topic))
      .set(
        'market',
        this.sessionStorage.getItem(
          this.defaultSettings.localStorageMarketVariable
        )
      )
      .set('language', newDefaultElement.language);

    const url = '/da/gdpr/entry/delete';
    let options: any = null;

    options = {
      params: params,
    };

    return this.http
      .post<any>(url, null, { ...options, responseType: 'text' })
      .toPromise();
  }

  ngOnDestroy(): void {
    // unsubscribe to the loading state
    this._progress.complete();
  }
}
