<form [formGroup]="newGdprContentForm" (ngSubmit)="onNewEntrySubmit()">
<groupui-table columns="4" sticky="true" header="true" widths="60px auto 145px 80px" indicator="default"
  scroll-y="true">
  <!-- table header -->
  <groupui-table-cell class="groupui-table-cell-header">{{'edit-gdpr.default' | translate}}</groupui-table-cell>
  <groupui-table-cell class="groupui-table-cell-header">{{'edit-gdpr.language' | translate}}</groupui-table-cell>
  <groupui-table-cell class="groupui-table-cell-header">{{'edit-gdpr.display-mode.title' | translate}}</groupui-table-cell>
  <groupui-table-cell class="groupui-table-cell-header"></groupui-table-cell>

    <!-- default language row -->
    <ng-template [ngIf]="defaultLanguageRow !== undefined">
      <groupui-table-cell class="groupui-table-cell-default-language checkbox-or-tag-cell">
        <groupui-checkbox checked="true" disabled="true"></groupui-checkbox>
      </groupui-table-cell>

      <groupui-table-cell class="groupui-table-cell-default-language language-cell">
        {{mapService.mapCountryCodeToDisplayCountryAndCountryCode(defaultLanguageRow.language, '-')}}
      </groupui-table-cell>

      <groupui-table-cell class="groupui-table-cell-default-language checkbox-or-tag-cell">
        <groupui-tag square="true" variant="primary">{{defaultLanguageRow.display_mode}}</groupui-tag>
      </groupui-table-cell>

      <groupui-table-cell class="groupui-table-cell-default-language button-group-cell">
        <groupui-button severity="none" target="self" icon-only="true" variant="tertiary" size="m"
          (click)="changeTableContent(changeGdprActionEnum.deleteDefault, defaultLanguageRow)">
          <div class="delete-button">
            <img src="assets/icons/edit-gdpr/trash-24.svg" alt="add new table entry" />
          </div>
        </groupui-button>
        <groupui-button severity="none" target="self" icon-only="true" variant="tertiary" size="m"
          (click)="changeTableContent(changeGdprActionEnum.update, defaultLanguageRow)">
          <div class="edit-button">
            <img src="assets/icons/edit-gdpr/edit-24.svg" alt="add new table entry" />
          </div>
        </groupui-button>
      </groupui-table-cell>
    </ng-template>
    <!-- table content -->
    <ng-template [ngIf]="tableContent !== undefined && tableContent.length > 0">
      <ng-container *ngFor="let tableRow of tableContent">
        <groupui-table-cell class="checkbox-or-tag-cell">
          <groupui-checkbox id="{{'checkbox-' + tableRow.language }}" (groupuiChange)="changeTableContent(changeGdprActionEnum.changeDefault, tableRow)" (keydown.enter)="changeTableContent(changeGdprActionEnum.changeDefault, tableRow)"></groupui-checkbox>
        </groupui-table-cell>

        <groupui-table-cell class="language-cell">
          {{mapService.mapCountryCodeToDisplayCountryAndCountryCode(tableRow.language, '-')}}
        </groupui-table-cell>

        <groupui-table-cell class="checkbox-or-tag-cell">
          <groupui-tag square="true" variant="primary">
            {{tableRow.display_mode}}
          </groupui-tag>
        </groupui-table-cell>

        <groupui-table-cell class="button-group-cell">
          <groupui-button severity="none" target="self" icon-only="true" variant="tertiary" size="m"
            (click)="changeTableContent(changeGdprActionEnum.delete, tableRow)">
            <div class="delete-button">
              <img src="assets/icons/edit-gdpr/trash-24.svg" alt="add new table entry" />
            </div>
          </groupui-button>
          <groupui-button severity="none" target="self" icon-only="true" variant="tertiary" size="m"
            (click)="changeTableContent(changeGdprActionEnum.update, tableRow)">
            <div class="edit-button">
              <img src="assets/icons/edit-gdpr/edit-24.svg" alt="add new table entry" />
            </div>
          </groupui-button>
        </groupui-table-cell>
      </ng-container>
    </ng-template>

  <!-- no data available - unhappy path -->
  <ng-template  [ngIf]="tableContent !== undefined && tableContent.length == 0">
    <groupui-table-cell>-</groupui-table-cell>
    <groupui-table-cell></groupui-table-cell>
    <groupui-table-cell></groupui-table-cell>
    <groupui-table-cell></groupui-table-cell>
  </ng-template>

  <!-- Add new GDPR Content -->
    <groupui-table-cell class="groupui-table-cell-footer checkbox-or-tag-cell">
      <groupui-checkbox [checked]="firstEntry" [disabled]="firstEntry" id="newDefaultLanguage" (click)="toggleNewDefaultLanguage()">
      </groupui-checkbox>
    </groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-footer dropdown-cell">
      <ng-select [ngClass]="{'invalid' : invalidSelection}" appendTo=".container" [items]="availableLanguages" placeholder="{{'edit-gdpr.language' | translate}}" bindLabel="displayCountry"
        bindValue="countryCode" formControlName="language">
        <ng-template ng-option-tmp let-item="item">{{item.displayCountry}} - {{item.countryCode}}</ng-template>
      </ng-select>
    </groupui-table-cell>
    <groupui-table-cell  class="groupui-table-cell-footer dropdown-cell">
      <ng-select [ngClass]="{'invalid' : invalidSelection}" appendTo=".container" placeholder="{{'edit-gdpr.display-mode.title' | translate}}" formControlName="display_mode">
        <ng-option [value]="'PDF'">{{'edit-gdpr.display-mode.pdf' | translate}}</ng-option>
        <ng-option [value]="'TEXT'">{{'edit-gdpr.display-mode.text' | translate}}</ng-option>
     </ng-select>
    </groupui-table-cell>
    <groupui-table-cell class="groupui-table-cell-footer plus-button">
      <groupui-button severity="none" target="self" icon-only="true" variant="tertiary" size="m" type="submit">
        <div class="add-button">
          <img src="assets/icons/edit-gdpr/add-24.svg" alt="add new table entry" />
        </div>
      </groupui-button>
    </groupui-table-cell>
</groupui-table>
</form>

